var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-flexbox cyui-detail-header"
        }, [_c('div', {
          staticClass: "cyui-flexbox-item cyui-detail-header-content"
        }, [_c('div', {
          staticClass: "cyui-detail-header-title"
        }, [_vm._v(" 维修" + _vm._s(_vm.statusMap[_vm.detailInfo.status] && _vm.statusMap[_vm.detailInfo.status]['text']) + " ")]), _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("维修类型")]), _c('span', [_vm._v(_vm._s(_vm.detailInfo.typeName || '-'))])]), _c('div', {
          staticClass: "cyui-detail-header-brief"
        }, [_c('span', [_vm._v("预约时间")]), _c('span', [_vm._v(_vm._s(_vm.dateFilter(_vm.detailInfo.visitingTime)))])])]), _c('div', {
          staticClass: "cyui-detail-header-extra"
        }, [_c('div', {
          staticClass: "cyui-detail-header-status"
        }, [`${_vm.detailInfo.status}` === '0' ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_1"
          }
        }) : `${_vm.detailInfo.status}` === '1' ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_6"
          }
        }) : `${_vm.detailInfo.status}` === '2' ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_5"
          }
        }) : `${_vm.detailInfo.status}` === '3' ? _c('svg-icon', {
          attrs: {
            "icon-class": "status_3"
          }
        }) : _vm._e()], 1)])]), _c('div', {
          staticClass: "cyui-section"
        }, [_c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-body",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('div', {
          staticClass: "cyui-section-client"
        }, [_c('cyui-field-list-info', {
          attrs: {
            "fields": _vm.clientInfo.baseInfoFields,
            "data": _vm.clientInfo.baseInfo,
            "title-width": `${_vm.formatPx2Rem(140)}rem`
          }
        })], 1)])]), _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 需求备注 ")])])]), _c('div', {
          staticClass: "cyui-card-body"
        }, [_vm.detailInfo ? _c('div', {
          staticClass: "cyui-section-remark"
        }, [_c('div', {
          staticClass: "cyui-section-remark-content"
        }, [_vm._v(" " + _vm._s(_vm.demandInfo.remark) + " ")]), _vm.demandInfo.videoFilesList.length + _vm.demandInfo.imgFilesList.length ? _c('div', {
          staticClass: "cyui-flexbox cyui-flexbox-wrap cyui-section-remark-pic"
        }, [_vm._l(_vm.demandInfo.videoFilesList, function (item, index) {
          return [_c('div', {
            key: `video${index}`,
            staticClass: "cyui-section-remark-pic-item",
            on: {
              "click": function ($event) {
                return _vm.handleVideoPreview(item);
              }
            }
          }, [_c('video', {
            ref: "myVideo",
            refInFor: true,
            staticClass: "cyui-video-box",
            attrs: {
              "src": item.url
            }
          }), _c('div', {
            staticClass: "cyui-video-overlay"
          }, [_c('svg-icon', {
            attrs: {
              "icon-class": "video"
            }
          })], 1)])];
        }), _vm._l(_vm.demandInfo.imgFilesList, function (item, index) {
          return [_c('div', {
            key: `pic${index}`,
            staticClass: "cyui-section-remark-pic-item",
            on: {
              "click": function ($event) {
                return _vm.handleImagePreview({
                  startPosition: index
                }, _vm.demandInfo.imgFilesList);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": item.url,
              "alt": ""
            }
          })])];
        })], 2) : _vm._e()]) : _c('div', {
          staticClass: "no-data"
        }, [_vm._v("暂无数据")])])]), _vm.detailInfo.status > 1 ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 维修详情补充 ")])])]), _c('div', {
          staticClass: "cyui-card-body"
        }, [_c('div', {
          staticClass: "cyui-section-field"
        }, [_c('cyui-field-list-info', {
          attrs: {
            "fields": _vm.supplementInfo.baseInfoFields,
            "data": _vm.supplementInfo.baseInfo,
            "title-width": `${_vm.formatPx2Rem(150)}rem`
          }
        })], 1), _vm.detailInfo ? _c('div', {
          staticClass: "cyui-section-service"
        }, [_c('div', {
          staticClass: "cyui-section-service-visits cyui-section-service-resolve"
        }, [_c('van-cell-group', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-cell', {
                staticClass: "cyui-section-service-visits-comment",
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function () {
                    return [_c('div', {
                      style: {
                        width: `${_vm.formatPx2Rem(150)}rem`
                      }
                    }, [_vm._v("问题详情：")])];
                  },
                  proxy: true
                }, {
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v(_vm._s(_vm.supplementInfo.remark))])];
                  },
                  proxy: true
                }], null, false, 2693527200)
              })];
            },
            proxy: true
          }], null, false, 1733368181)
        })], 1)]) : _vm._e(), _vm.detailInfo ? _c('div', {
          staticClass: "cyui-section-remark"
        }, [_vm.supplementInfo.videoFilesList.length + _vm.supplementInfo.imgFilesList.length ? _c('div', {
          staticClass: "cyui-flexbox cyui-flexbox-wrap cyui-section-remark-pic"
        }, [_vm._l(_vm.supplementInfo.videoFilesList, function (item, index) {
          return [_c('div', {
            key: `supplementVideo${index}`,
            staticClass: "cyui-section-remark-pic-item",
            on: {
              "click": function ($event) {
                return _vm.handleVideoPreview(item);
              }
            }
          }, [_c('video', {
            ref: "myVideo",
            refInFor: true,
            staticClass: "cyui-video-box",
            attrs: {
              "src": item.url
            }
          }), _c('div', {
            staticClass: "cyui-video-overlay"
          }, [_c('svg-icon', {
            attrs: {
              "icon-class": "video"
            }
          })], 1)])];
        }), _vm._l(_vm.supplementInfo.imgFilesList, function (item, index) {
          return [_c('div', {
            key: `supplementPic${index}`,
            staticClass: "cyui-section-remark-pic-item",
            on: {
              "click": function ($event) {
                return _vm.handleImagePreview({
                  startPosition: index
                }, _vm.supplementInfo.imgFilesList);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": item.url,
              "alt": ""
            }
          })])];
        })], 2) : _vm._e()]) : _vm._e(), _vm.detailInfo ? _c('div', {
          staticClass: "cyui-section-service"
        }, [_c('div', {
          staticClass: "cyui-section-service-visits cyui-section-service-resolve"
        }, [_c('van-cell-group', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('van-cell', {
                staticClass: "cyui-section-service-visits-comment",
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function () {
                    return [_c('div', {
                      style: {
                        width: `${_vm.formatPx2Rem(150)}rem`
                      }
                    }, [_vm._v("解决措施：")])];
                  },
                  proxy: true
                }, {
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v(_vm._s(_vm.detailInfo.repairmanResolvent))])];
                  },
                  proxy: true
                }], null, false, 4008104013)
              })];
            },
            proxy: true
          }], null, false, 1052963071)
        })], 1)]) : _c('div', {
          staticClass: "no-data"
        }, [_vm._v("暂无数据")])])]) : _vm._e(), `${_vm.detailInfo.isEvaluate}` === '1' ? _c('div', {
          staticClass: "cyui-card cyui-section-item"
        }, [_c('div', {
          staticClass: "cyui-card-header"
        }, [_c('div', {
          staticClass: "cyui-card-header-line"
        }, [_c('div', {
          staticClass: "cyui-card-header-content"
        }, [_vm._v(" 服务评价 ")])])]), _c('div', {
          staticClass: "cyui-card-body"
        }, [_vm.detailInfo ? _c('div', {
          staticClass: "cyui-section-service"
        }, [_c('div', {
          staticClass: "cyui-section-service-rate"
        }, [_c('van-cell-group', _vm._l(_vm.serviceInfo.rateInfoFields, function (item, index) {
          return _c('van-cell', {
            key: `rateInfo${index}`,
            scopedSlots: _vm._u([{
              key: "title",
              fn: function () {
                return [_c('div', {
                  style: {
                    width: `${_vm.formatPx2Rem(160)}rem`
                  }
                }, [_vm._v(_vm._s(item.title))])];
              },
              proxy: true
            }, {
              key: "default",
              fn: function () {
                return [_c('van-rate', {
                  attrs: {
                    "icon": _vm.img002,
                    "void-icon": _vm.img003,
                    "readonly": ""
                  },
                  model: {
                    value: _vm.serviceInfo.rateInfo[item.key],
                    callback: function ($$v) {
                      _vm.$set(_vm.serviceInfo.rateInfo, item.key, $$v);
                    },
                    expression: "serviceInfo.rateInfo[item.key]"
                  }
                })];
              },
              proxy: true
            }], null, true)
          });
        }), 1)], 1), _c('div', {
          staticClass: "cyui-section-service-visits"
        }, [_c('van-cell-group', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('div', [_vm._v("是否会再次使用该服务？")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('van-cell', {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function () {
                    return [_c('div', {
                      style: {
                        width: `${_vm.formatPx2Rem(90)}rem`
                      }
                    }, [_vm._v("回答：")])];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function () {
                    return [_c('div', [_vm._v(_vm._s(_vm._f("filterIsReuse")(_vm.detailInfo.isReuse)))])];
                  },
                  proxy: true
                }], null, false, 416197048)
              }), _c('van-cell', {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function () {
                    return [_c('div', {
                      style: {
                        width: `${_vm.formatPx2Rem(90)}rem`
                      }
                    }, [_vm._v("原因：")])];
                  },
                  proxy: true
                }, {
                  key: "default",
                  fn: function () {
                    return [_c('div', [_vm._v(_vm._s(_vm.fieldEmptyFilter(_vm.detailInfo.reason)))])];
                  },
                  proxy: true
                }], null, false, 3521025318)
              }), _c('van-cell', {
                staticClass: "cyui-section-service-visits-comment",
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function () {
                    return [_c('div', {
                      style: {
                        width: `${_vm.formatPx2Rem(150)}rem`
                      }
                    }, [_vm._v("用户评价：")])];
                  },
                  proxy: true
                }, {
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v(_vm._s(_vm.fieldEmptyFilter(_vm.detailInfo.proposal)))])];
                  },
                  proxy: true
                }], null, false, 301327917)
              })];
            },
            proxy: true
          }], null, false, 1434595375)
        })], 1)]) : _c('div', {
          staticClass: "no-data"
        }, [_vm._v("暂无数据")])])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "closeable": "",
      "close-icon": "clear"
    },
    on: {
      "close": _vm.handleVideoPause
    },
    model: {
      value: _vm.videoPopupInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.videoPopupInfo, "show", $$v);
      },
      expression: "videoPopupInfo.show"
    }
  }, [_c('div', {
    staticClass: "cyui-popup-video",
    on: {
      "click": function ($event) {
        _vm.videoPopupInfo.show = false;
      }
    }
  }, [_c('div', {
    staticClass: "cyui-popup-video-body"
  }, [_c('cyui-player', {
    ref: "cyuiPlayer",
    attrs: {
      "options": _vm.videoPopupInfo.options
    }
  })], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };